import { produce } from 'immer';
import { mutateAddColumns, mutateUpdateColumn } from '../utils/columns';
import {
  computeContainerHeight,
  mutateDropInContainer,
  mutateUpdateContainer,
} from '../utils/containers';
import { isATitleBox, mutateAddBoxes, mutateRemoveBox } from '../utils/boxes';
import { ItemTypes } from '../constants/constants';

const checkIfTitleBoxCanDrop = ({newBoxes, drawMode, boxes, columnId}) => {
  if(isATitleBox(newBoxes[0].type) && drawMode) {
      const boxesInContainer = boxes.filter((b) => b.columnId === columnId);
      const topTitleBoxInDrawModeContainer = boxesInContainer
                .filter((b) => isATitleBox(b.type))
                .reduce((minBox, currentBox) => {
                return minBox === null || currentBox.top < minBox.top ? currentBox : minBox;
                }, null);

      const titleIndices = {
            [ItemTypes.TITLE]: 1,
            [ItemTypes.TITLE_2]: 2,
            [ItemTypes.TITLE_3]: 3,
            [ItemTypes.TITLE_4]: 4,
      }

      if(topTitleBoxInDrawModeContainer &&
        titleIndices[newBoxes[0].type] > titleIndices[topTitleBoxInDrawModeContainer.type] &&
        newBoxes[0].top < topTitleBoxInDrawModeContainer.top) {
        return false;
      }
  }
}

export const columnsActions = (set, get) => ({
  /* COLUMNS */
  addColumns: (newColumns = []) => {
    set(
      produce((draft) => {
        mutateAddColumns(draft, newColumns);
      }),
      false,
      `add ${newColumns.length} new Columns`
    );
  },
  dropBoxInColumn: ({
    id,
    drawMode = false,
    updateContainerHeight = false,
    newBox,
    maskId = null,
    boxIdToReplace,
  }) => {
    set(
      produce((draft) => {
        const column = draft.columns.find((c) => c.id === id);
        const newBoxes = newBox.type === ItemTypes.DRAW ? [] : [newBox];
        if(checkIfTitleBoxCanDrop({newBoxes, drawMode, boxes: draft.boxes, columnId: id}) === false) return false;
        if (updateContainerHeight) {
          const container = draft.containers.find((c) =>
            c.columnsIds.includes(id)
          );
          const containerHeight = computeContainerHeight({
            drawMode,
            landscape: draft.landscape,
            boxType: newBox.type,
          });
          mutateUpdateContainer(draft, {
            ...container,
            height: containerHeight,
          });
        }

        mutateUpdateColumn(draft, { ...column, drawMode });

        if (boxIdToReplace) {
          mutateRemoveBox(draft, boxIdToReplace);
        }

        mutateAddBoxes({ draft, newBoxes, drawMode, maskId });
        if (!maskId) {
          const container = draft.containers.find((c) =>
            c.columnsIds.includes(id)
          );
          mutateDropInContainer({ draft, container, box: newBox });
        }
      }),
      false,
      `Drop box in column ${id}`
    );
  },
});
